.main[data-v-0ae79869] {
  margin-top: 20px;
}
.main p[data-v-0ae79869] {
  color: #606266;
}
.main .parameter-box[data-v-0ae79869] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main .parameter-box .left-pane[data-v-0ae79869] {
  width: 350px;
  height: 323px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  margin: 0 10px 18px 20px;
}
.main .parameter-box .left-pane .left-pane-list[data-v-0ae79869] {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.main .parameter-box .left-pane .left-pane-list .list[data-v-0ae79869] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.main .parameter-box .left-pane .left-pane-list .list .header[data-v-0ae79869] {
  background-color: #f5f7fa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main .parameter-box .left-pane .left-pane-list .list .header span[data-v-0ae79869] {
  font-size: 12px;
  color: #606266;
  padding: 6px 10px;
  display: inline-block;
  line-height: 23px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.main .parameter-box .left-pane .left-pane-list .list .header .operation[data-v-0ae79869] {
  width: 70px;
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
}
.main .parameter-box .left-pane .left-pane-list[data-v-0ae79869] .el-input {
  width: auto;
  margin: 5px 10px;
}
.main .parameter-box .left-pane .left-pane-list[data-v-0ae79869] .el-table {
  border-top: unset;
}
.main .parameter-box .left-pane .left-pane-list[data-v-0ae79869] .el-icon-edit-outline,
.main .parameter-box .left-pane .left-pane-list[data-v-0ae79869] .el-icon-delete {
  font-size: 16px;
}
.main .parameter-box .right-pane[data-v-0ae79869] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main .parameter-box .right-pane .msg-pane[data-v-0ae79869] {
  margin-left: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main .parameter-box .right-pane .msg-pane .list[data-v-0ae79869] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.main .parameter-box .right-pane .msg-pane[data-v-0ae79869] .el-icon-delete {
  font-size: 16px;
}
.main .parameter-box .right-pane[data-v-0ae79869] .ql-editor {
  min-height: 300px !important;
}