.avue-left[data-v-56aa917e] {
  padding-bottom: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.hamburger-container[data-v-56aa917e] {
  position: absolute;
  width: 100%;
  bottom: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: right;
}
.avue--collapse .hamburger-container[data-v-56aa917e] {
  text-align: center;
}