.node-wrap-box[data-v-55c27a51] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.node-wrap-box.condition[data-v-55c27a51], .node-wrap-box.branchFlow[data-v-55c27a51], .node-wrap-box.interflow[data-v-55c27a51] {
  padding: 30px 50px 0;
}
.node-wrap-box.empty[data-v-55c27a51] {
  overflow: hidden;
}
.node-wrap-box.approver[data-v-55c27a51]::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 4px;
  border-style: solid;
  border-width: 8px 6px 4px;
  border-color: #A9B4CD transparent transparent;
  background: #ebeef5;
}
.node-wrap-box.approver.branchFlow[data-v-55c27a51]::before, .node-wrap-box.approver.interflow[data-v-55c27a51]::before {
  top: 20px;
}
.node-wrap-box.error.condition .error-tip[data-v-55c27a51], .node-wrap-box.error.branchFlow .error-tip[data-v-55c27a51], .node-wrap-box.error.interflow .error-tip[data-v-55c27a51] {
  right: 0;
}
.node-wrap-box.error .error-tip[data-v-55c27a51] {
  right: -40px;
}
.node-wrap-box.error .flow-path-card[data-v-55c27a51] {
  border: 1px solid #f00;
}
.node-wrap-box.error .flow-path-card[data-v-55c27a51]:hover {
  border-width: 0;
}
.node-wrap-box .error-tip[data-v-55c27a51] {
  position: absolute;
  right: 1px;
  top: 15%;
  width: 30px;
  height: 30px;
  color: #f00;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid;
  line-height: 30px;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
}
.node-wrap-box.condition .error-tip[data-v-55c27a51], .node-wrap-box.branchFlow .error-tip[data-v-55c27a51], .node-wrap-box.interflow .error-tip[data-v-55c27a51] {
  right: 60px;
}
.end-node[data-v-55c27a51] {
  font-size: 12px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.end-node[data-v-55c27a51]::before {
  content: "";
  width: 10px;
  height: 10px;
  margin: auto;
  border: none;
  margin-bottom: 12px;
  border-radius: 50%;
  background: #A9B4CD;
}
.flow-path-card[data-v-55c27a51] {
  width: 220px;
  min-height: 82px;
  font-size: 12px;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  background: #FFF;
  border-radius: 2px;
  font-size: 12px;
}
.flow-path-card[data-v-55c27a51]:hover {
  -webkit-box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(0, 0, 0, 0.2);
}
.flow-path-card.copy .header[data-v-55c27a51] {
  background-color: #1890ff;
}
.flow-path-card.timer .header[data-v-55c27a51] {
  color: #f5811c;
  border-bottom: 1px solid #EEEEEE;
}
.flow-path-card.timer .header .actions[data-v-55c27a51] {
  color: #606266;
}
.flow-path-card.approver[data-v-55c27a51]:hover, .flow-path-card.subFlow[data-v-55c27a51]:hover {
  -webkit-box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(0, 0, 0, 0.2);
}
.flow-path-card.approver .header[data-v-55c27a51], .flow-path-card.subFlow .header[data-v-55c27a51] {
  background-color: #1890ff;
}
.flow-path-card.start-node[data-v-55c27a51]:hover {
  -webkit-box-shadow: 0 0 0 2px #576a95, 0 0 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 2px #576a95, 0 0 5px 4px rgba(0, 0, 0, 0.2);
}
.flow-path-card.start-node .header[data-v-55c27a51] {
  background-color: #576a95;
}
.flow-path-card .header[data-v-55c27a51] {
  padding-left: 10px;
  padding-right: 30px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: white;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flow-path-card .header .title-box[data-v-55c27a51] {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flow-path-card .header .title-input[data-v-55c27a51] {
  position: absolute;
  left: 0;
  border: none;
  background: inherit;
  color: inherit;
  opacity: 0;
  margin-top: 6px;
}
.flow-path-card .header .title-input[data-v-55c27a51]:focus {
  border-radius: 4px;
  font-size: 12px;
  padding: 2px;
  padding-left: 4px;
  width: 97%;
  margin-left: 1px;
  height: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 1px 1px #1890ff;
          box-shadow: 0 0 1px 1px #1890ff;
  background-color: #ebeef5;
  color: black;
  opacity: 1;
}
.flow-path-card .header .title-text[data-v-55c27a51] {
  vertical-align: middle;
}
.flow-path-card .header > .actions[data-v-55c27a51] {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
}
.flow-path-card .header > .async-state[data-v-55c27a51] {
  position: absolute;
  right: 25px;
  top: 5px;
}
.flow-path-card.timer:hover .actions[data-v-55c27a51] {
  visibility: visible;
  margin-right: 4px;
}
.flow-path-card.subFlow .header .title-box[data-v-55c27a51] {
  width: 140px !important;
}
.flow-path-card:not(.start-node):not(.timer):hover .actions[data-v-55c27a51] {
  visibility: visible;
  margin-right: 4px;
}
.flow-path-card:not(.start-node):not(.timer):hover .title-text[data-v-55c27a51] {
  border-bottom: 1px dashed currentColor;
}
.flow-path-card.start-node:hover .title-text[data-v-55c27a51] {
  border-bottom: 1px dashed currentColor;
}
.flow-path-card .body[data-v-55c27a51] {
  position: relative;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flow-path-card .body .text[data-v-55c27a51] {
  word-break: break-all;
  margin: 0 ellipsis(4);
}
.flow-path-card .icon-wrapper[data-v-55c27a51] {
  position: absolute;
  top: 0;
  height: 100%;
  width: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flow-path-card .icon-wrapper.left[data-v-55c27a51] {
  left: 0;
}
.flow-path-card .icon-wrapper.right[data-v-55c27a51] {
  right: 0;
}
.flow-path-card .icon-wrapper > .right-arrow[data-v-55c27a51],
.flow-path-card .icon-wrapper > .left-arrow[data-v-55c27a51] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.flow-path-card.condition .header[data-v-55c27a51] {
  line-height: 30px;
  color: inherit;
  border-bottom: 1px solid #EEEEEE;
}
.flow-path-card.condition .header .title-box[data-v-55c27a51] {
  height: auto !important;
}
.flow-path-card.condition .header .title-text[data-v-55c27a51] {
  color: #15bc83;
}
.flow-path-card.condition .body[data-v-55c27a51] {
  padding: 10px;
  color: #606266;
}
.flow-path-card.condition .icon-wrapper[data-v-55c27a51]:hover {
  background-color: #f1f1f1;
}
.flow-path-card.condition .right-arrow[data-v-55c27a51],
.flow-path-card.condition .left-arrow[data-v-55c27a51] {
  visibility: hidden;
}
.flow-path-card.condition:hover .right-arrow[data-v-55c27a51],
.flow-path-card.condition:hover .left-arrow[data-v-55c27a51] {
  visibility: visible;
}
.flow-path-card.condition:hover .priority[data-v-55c27a51] {
  display: none;
}
.col-box:first-of-type > .node-wrap .left[data-v-55c27a51] {
  display: none;
}
.col-box:last-of-type > .node-wrap .right[data-v-55c27a51] {
  display: none;
}
.add-node-btn-box[data-v-55c27a51] {
  width: 220px;
  height: 100px;
  position: relative;
  padding-top: 30px;
  margin: auto;
}
.add-node-btn-box[data-v-55c27a51]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: auto;
  width: 1px;
  height: 100%;
  background-color: #A9B4CD;
}
.add-node-btn-box .add-node-btn[data-v-55c27a51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.add-node-btn-box .add-node-btn .btn[data-v-55c27a51] {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  background-color: #1890ff;
  border-color: transparent;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.add-node-btn-box .add-node-btn .btn[data-v-55c27a51]:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}
.add-node-btn-box .add-node-btn .btn .icon[data-v-55c27a51] {
  color: white;
}
.branch-wrap .branch-box-wrap[data-v-55c27a51] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.branch-wrap .branch-box[data-v-55c27a51] {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  border-bottom: 1px solid #A9B4CD;
  border-top: 1px solid #A9B4CD;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ebeef5;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:first-of-type::before, .branch-wrap .branch-box > .col-box[data-v-55c27a51]:first-of-type::after {
  content: "";
  position: absolute;
  left: 0;
  height: 3px;
  width: calc(50% - 1px);
  background: #ebeef5;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:first-of-type::before {
  top: -2px;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:first-of-type::after {
  bottom: -2px;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:last-of-type::before, .branch-wrap .branch-box > .col-box[data-v-55c27a51]:last-of-type::after {
  content: "";
  position: absolute;
  right: 0;
  height: 3px;
  width: calc(50% - 1px);
  background: #ebeef5;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:last-of-type::before {
  top: -2px;
}
.branch-wrap .branch-box > .col-box[data-v-55c27a51]:last-of-type::after {
  bottom: -2px;
}
.branch-wrap .branch-box > .col-box .center-line[data-v-55c27a51] {
  height: 100%;
  width: 1px;
  background: #A9B4CD;
  position: absolute;
}
.branch-wrap .branch-box > .btn[data-v-55c27a51] {
  font-size: 14px;
  z-index: 99;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 50%;
  outline: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 9px 16px;
  border: none;
  border-radius: 15px;
  background: white;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  color: #1890ff;
}
.branch-wrap .branch-box > .btn[data-v-55c27a51]:hover {
  -webkit-transform: scale(1.1) translate(-46%, -50%);
          transform: scale(1.1) translate(-46%, -50%);
}
.condition-box[data-v-55c27a51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding: 10px 0;
}
.condition-box > div:nth-child(1) .iconfont[data-v-55c27a51] {
  color: #FF943E;
}
.condition-box > div:nth-child(2) .iconfont[data-v-55c27a51] {
  color: #3296FA;
}
.condition-box .condition-disabled[data-v-55c27a51] {
  color: #C0C4CC;
}
.condition-box .condition-disabled .condition-icon[data-v-55c27a51] {
  background: #e5e5e5;
  color: #999;
  cursor: default;
}
.condition-box .condition-disabled .condition-icon[data-v-55c27a51]:hover {
  background: #e5e5e5;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.condition-box .condition-disabled .condition-icon:hover > .icon-ym[data-v-55c27a51],
.condition-box .condition-disabled .condition-icon:hover > [class^=el-icon-][data-v-55c27a51],
.condition-box .condition-disabled .condition-icon:hover > .ym-custom[data-v-55c27a51] {
  color: #999;
}
.condition-box .condition-icon[data-v-55c27a51] {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
}
.condition-box .condition-icon .icon-ym[data-v-55c27a51],
.condition-box .condition-icon [class^=el-icon-][data-v-55c27a51],
.condition-box .condition-icon .ym-custom[data-v-55c27a51] {
  font-size: 32px;
}
.condition-box .condition-icon[data-v-55c27a51]:hover {
  background: #3296FA;
  -webkit-box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
}
.condition-box .condition-icon:hover > .icon-ym[data-v-55c27a51],
.condition-box .condition-icon:hover > [class^=el-icon-][data-v-55c27a51],
.condition-box .condition-icon:hover > .ym-custom[data-v-55c27a51] {
  color: white;
}
.relative[data-v-55c27a51] {
  position: relative;
}
.flex[data-v-55c27a51] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.justify-center[data-v-55c27a51] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.icon[data-v-55c27a51] {
  vertical-align: middle;
  width: 14px;
  height: 14px;
  font-size: 14px;
}
.priority[data-v-55c27a51] {
  position: absolute;
  right: 0;
  font-size: 12px;
}
input[data-v-55c27a51]::-ms-clear,
input[data-v-55c27a51]::-ms-reveal {
  display: none;
}