.hand[data-v-9945bc38] input {
  cursor: pointer;
}
.tips p[data-v-9945bc38] {
  line-height: 24px;
}
.drawer[data-v-9945bc38] .el-drawer__body {
  padding-bottom: 62px !important;
  overflow: hidden;
}
.drawer .el-select[data-v-9945bc38] {
  width: 100%;
}
.drawer .el-select.timeout-select[data-v-9945bc38] {
  width: 80px;
}
.drawer[data-v-9945bc38] .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
.drawer[data-v-9945bc38] .el-tabs__item:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.drawer[data-v-9945bc38] .el-tabs__header {
  margin-bottom: 0;
}
.drawer[data-v-9945bc38] .el-tabs__content {
  background-color: #fff;
}
.mr-10[data-v-9945bc38] {
  margin-right: 10px;
}
.header[data-v-9945bc38] {
  line-height: 28px;
}
.actions[data-v-9945bc38] {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: right;
}
.radio-item[data-v-9945bc38] {
  line-height: 30px;
  width: calc(25% - 30px);
}
.priority-select[data-v-9945bc38] {
  width: 118px;
  position: absolute;
  right: 26px;
}
.form-auth-table[data-v-9945bc38] {
  height: 100%;
  font-size: 14px;
}
.form-auth-table[data-v-9945bc38] .el-checkbox__label {
  font-size: 12px;
}
.form-auth-table .auth-table-header[data-v-9945bc38] {
  background: #fafafa;
  line-height: 40px;
}
.form-auth-table .row[data-v-9945bc38] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 32px;
  padding: 8px 12px;
  border-bottom: 1px solid #efefef;
}
.form-auth-table .row[data-v-9945bc38]:hover {
  background: #f5f7fa;
}
.form-auth-table .row .label[data-v-9945bc38] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-auth-table .row .label .required[data-v-9945bc38] {
  color: #f2725e;
}
.form-auth-table .row .radio-group[data-v-9945bc38] {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.approver-pane[data-v-9945bc38] {
  height: 100%;
  overflow: hidden;
}
.approver-pane[data-v-9945bc38] .el-tabs__content {
  height: calc(100% - 40px);
  background-color: #fff;
}
.approver-pane[data-v-9945bc38] .el-tabs__content .el-tab-pane {
  height: 100%;
}
.approver-pane[data-v-9945bc38] .el-tabs__content .el-tab-pane .config-scrollbar {
  height: 100%;
}
.approver-pane[data-v-9945bc38] .el-tabs__content .el-tab-pane .config-scrollbar .el-row {
  font-size: 14px;
  color: #606266;
  height: 32px;
  line-height: 32px;
}
.approver-pane .per-cell[data-v-9945bc38] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.approver-pane .per-cell .el-checkbox[data-v-9945bc38] {
  margin-right: 40px;
}
.approver-pane .per-cell[data-v-9945bc38]:last-child {
  margin-bottom: unset;
}
.option-box-tip[data-v-9945bc38] {
  font-size: 14px;
  color: #a5a5a5;
}
.option-box[data-v-9945bc38] {
  font-size: 14px;
  padding-left: 1rem;
}
.condition-pane[data-v-9945bc38] {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.condition-list[data-v-9945bc38] {
  font-size: 14px;
  line-height: 36px;
}
.condition-list.condition-list-header[data-v-9945bc38] .el-col {
  text-align: left;
}
.condition-list[data-v-9945bc38] .el-col {
  text-align: center;
  padding: 0 4px;
}
.condition-list[data-v-9945bc38] .el-col.label, .condition-list[data-v-9945bc38] .el-col.fieldValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.condition-list[data-v-9945bc38] .el-col .el-input,
.condition-list[data-v-9945bc38] .el-col .el-input-number,
.condition-list[data-v-9945bc38] .el-col .el-select {
  width: 100%;
}
.condition-list[data-v-9945bc38] .el-col .el-icon-delete {
  cursor: pointer;
}
.condition-list[data-v-9945bc38] .el-col .el-icon-delete:hover {
  color: #f2725e;
}
.condition-list[data-v-9945bc38] .el-col .edit-script-btn {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 32px;
}
.condition-list .condition-select.el-select.condition-type-select[data-v-9945bc38] {
  width: 80px !important;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.condition-list .condition-select.el-select[data-v-9945bc38] .el-input__inner {
  padding: 0 26px 0 10px;
}
.rule-dialog[data-v-9945bc38] .el-dialog__body {
  padding: 0 0 10px !important;
  min-height: 364px;
}
.rule-dialog .node-tabs[data-v-9945bc38] .el-tabs__nav-wrap {
  padding: 0 20px;
}
.rule-dialog .node-tabs[data-v-9945bc38] .el-tabs__content .el-tab-pane {
  min-height: 300px !important;
  max-height: 500px !important;
  padding: 0 10px 10px;
  overflow: auto;
}
.rule-dialog .option-box-tip[data-v-9945bc38] {
  margin-bottom: 20px;
}
.rule-dialog .rule-cell[data-v-9945bc38] {
  line-height: 32px;
}
.rule-dialog .rule-cell.mid[data-v-9945bc38] {
  color: #1890ff;
  text-align: center;
}
.parameter-box[data-v-9945bc38] {
  height: 30px;
  line-height: 30px;
  margin-bottom: 8px;
}
.variable-box[data-v-9945bc38] {
  margin-bottom: 8px;
}
.form-item-label[data-v-9945bc38] {
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
}
.form-sub-title[data-v-9945bc38] {
  font-size: 14px;
  color: #606266;
  line-height: 32px;
}
.form-item-content[data-v-9945bc38] {
  padding: 0 10px;
}
.form-item-content.form-item-content-first[data-v-9945bc38] {
  margin: -12px 0 18px;
}
.el-form-item[data-v-9945bc38] {
  margin-bottom: 12px !important;
}
.has-free-approver[data-v-9945bc38] {
  width: 92px;
}
.assignee-form[data-v-9945bc38] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.assignee-form .form-field-type[data-v-9945bc38] {
  width: 120px;
}
.assignee-form .form-field-type[data-v-9945bc38] .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 5px 0px 0px 5px;
}
.assignee-form .form-field[data-v-9945bc38] .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0px 5px 5px 0px;
  border-left: 0px solid #dcdfe6;
}
[data-v-9945bc38] .el-drawer__header {
  padding: 0 20px !important;
}