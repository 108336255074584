.flow-container[data-v-71e54f9c] {
  display: inline-block;
  background: #ebeef5;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  overflow: auto;
}
.scale-slider[data-v-71e54f9c] {
  position: fixed;
  right: 0;
  z-index: 99;
}
.scale-slider .btn[data-v-71e54f9c] {
  display: inline-block;
  padding: 4px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}